import type { Session } from 'next-auth'
import type { HubDashboard } from './configuration'

export class CustomError extends Error {
  constructor(title: string, message: string) {
    super(message)
    this.name = 'CustomError'
    // @ts-expect-error we are setting the title
    this.title = title
    // @ts-expect-error we are setting the statusMessage
    this.statusMessage = message
  }
}

export type CustomErrorType = { title: string, statusMessage: string } & Error

export interface Release {
  cultureName?: string // exists on the post request but not the get
  releaseNumber: string
  releaseType: 'major' | 'minor' | 'patch'
  buildNumber: string
  date: string
  new: string[]
  improvements: string[]
  bugs: string[]
  contributors: string[]
}

export type TruMetricObject = {
  name: string
  displayName: string
  icon?: string
  img?: string
  color: string
  hexColor: string
}

export const truMetricsArray: Array<TruMetricObject> = [
  {
    name: 'ease-of-use',
    displayName: 'Ease Of Use',
    icon: 'i-mdi-cursor-default-outline',
    color: 'metric-service',
    hexColor: '#BB84FC'
  },
  {
    name: 'service',
    displayName: 'Service',
    icon: 'i-mdi-face-man',
    color: 'metric-service',
    hexColor: '#BB84FC'
  },
  {
    name: 'recommend',
    displayName: 'Recommend',
    icon: 'i-mdi-thumb-up-outline',
    color: 'metric-recommend',
    hexColor: '#2CEABD'
  },
  {
    name: 'experience',
    displayName: 'Experience',
    icon: 'i-mdi-emoticon-happy-outline',
    color: 'metric-experience',
    hexColor: '#F8DA30'
  },
  {
    name: 'value',
    displayName: 'Value',
    icon: 'i-mdi-wallet-outline',
    color: 'metric-value',
    hexColor: '#F7A536'
  },
  {
    name: 'product',
    displayName: 'Product',
    icon: 'i-mdi-tag-outline',
    color: 'metric-product',
    hexColor: '#FF90D9'
  },
  {
    name: 'trurating',
    displayName: 'TruRating',
    img: 'tru-logo-button-dark.svg',
    color: 'trurating-blue',
    hexColor: '#48C3FF'
  }
] as const
export type TruMetric = (typeof truMetricsArray)[number]

export const sentimentColors = {
  positive: '#8FD78E',
  mixed: '#F7A536',
  negative: '#FF4560',
  neutral: '#ABD9E9'
}

export interface HubUser {
  id: string
  name: string | null
  email: string | null
  image?: string | null
  impersonatorName?: string
  permissions?: string[]
}
export interface HubSession extends Session {
  accessToken: string
  signOutUrl: string
  user: HubUser
}

export interface HubUserPreference {
  userId: string
  value: string
  name: 'DefaultDashboardId' | 'LastAcknowledgedRelease' | 'LastIgnoredRelease' | 'LastIgnoredReleaseTime'
  type: string
}

export interface HubFilter {
  dateFrom?: string
  dateTo?: string
  relativeDate?: string
  touchpointIds?: Array<string>
  organisationNodeIds?: Array<string>
  // tags?: Array<string>
}

export interface HubSidebarItem {
  id: string | number
  header: string
  children: Array<HubDashboard>
}

export interface HubMultiCheckboxItem {
  children?: Array<HubMultiCheckboxItem>
  selected: boolean
  disabled: boolean
  childrenVisible: boolean
  object: { [name: string]: any }
}

export type OrganisationQuickFilter = {
  id: string
  name: string
  rootOrganisationNodeId: number
  createdDate: Date
  updatedDate: Date
  config: HubFilter & {
    country?: string
    organisationNodeIds?: string[]
    touchpointIds?: string[]
    totalOutlets?: number
  }
}

export type QuickFilterView = 'list' | 'delete' | 'add'

export type NewQuickFilter = {
  selectedTouchpoints?: boolean
  selectedOutlets?: boolean
  relativeDate?: boolean
  name: string
  dateRange?: boolean
}
interface Dictionary<T> {
  [Key: string]: T
}
export type HealthCheckResult = {
  status?: string
  description?: string
  data: Dictionary<string>
}

export type HealthCheckResults = {
  version_check: HealthCheckResult
}
export type HealthCheck = {
  status?: string
  results?: HealthCheckResults
}
